import * as React from 'react';
import { useTranslations } from '../../common/translation';
import { AspectRatioImage } from '../aspect-ratio-image/AspectRatioImage';
import { SuperLink } from '../super-link/SuperLink';
import { ImageCardProps, ImageCardsProps } from './ImageCards.props';
import {
  ImageCard,
  ImageCardHeader,
  ImageCardIconContainer,
  ImageCardSubtitle,
  ImageCardTitle,
  ImageCardType,
  ImageCardTypeContainer,
  StyledImageCards,
  StyledImageCardsInner,
} from './ImageCards.styles';
import translations from './translations';

export const ImageCards = ({
  cards,
  headingTag = 'h3',
  invertColors,
  lazyLoad,
  showCategories,
  ratio,
}: ImageCardsProps) => {
  const { t } = useTranslations(translations);

  if (!(cards || []).length) {
    return null;
  }

  return (
    <StyledImageCards>
      <StyledImageCardsInner invertColors={invertColors}>
        {cards.map(({ alt, categories, src, subtitle, title, to }: ImageCardProps) => {
          let image;
          if (typeof src === 'string') {
            image = <AspectRatioImage image={{ src, alt: alt || title }} lazyLoad={lazyLoad} ratio={ratio} />;
          } else {
            const SvgIcon = src;
            image = (
              <ImageCardIconContainer ratio={ratio}>
                <SvgIcon />
              </ImageCardIconContainer>
            );
          }

          return (
            <ImageCard key={to}>
              <SuperLink tabIndex={-1} target='_blank' to={to}>
                {image}
              </SuperLink>
              <section>
                <ImageCardHeader>
                  {showCategories && (
                    <ImageCardTypeContainer usePlaceholderPadding={!categories}>
                      {categories &&
                        categories.split(', ').map((category) => (
                          <ImageCardType key={category} category={category}>
                            {t(category, category)}
                          </ImageCardType>
                        ))}
                    </ImageCardTypeContainer>
                  )}
                  <SuperLink target='_blank' to={to}>
                    <ImageCardTitle as={headingTag}>{title}</ImageCardTitle>
                  </SuperLink>
                  {subtitle && <ImageCardSubtitle>{subtitle}</ImageCardSubtitle>}
                </ImageCardHeader>
              </section>
            </ImageCard>
          );
        })}
      </StyledImageCardsInner>
    </StyledImageCards>
  );
};

import * as React from 'react';
import { StyledPaddingContainer } from '../../common/padding.styles';
import { FeaturedBanner } from '../../components/featured-banner/FeaturedBanner';
import { HeadTitle } from '../../components/head-title/HeadTitle';
import { ImageCards } from '../../components/image-cards/ImageCards';
import { ImageCardProps } from '../../components/image-cards/ImageCards.props';
import { RowHeader } from '../../components/row-header/RowHeader';
import { DefaultLayout } from '../../layout/default/DefaultLayout';
import AlumniImage from './creative-talent/assets/images/alumni-article-960x768.jpg';
import BaftaImage from './creative-talent/assets/images/bafta-article-960x768.jpg';
import BfiImage from './creative-talent/assets/images/bfi-article-960x768.jpg';
import CreativeTalentMobileImage from './creative-talent/assets/images/creative-talent-mobile.jpg';
import CreativeTalentTabletImage from './creative-talent/assets/images/creative-talent-tablet.jpg';
import CreativeTalentImage from './creative-talent/assets/images/creative-talent.jpg';
import GamesIndustryImage from './creative-talent/assets/images/games-industry-article-960x768.jpg';
import PartnersImage from './creative-talent/assets/images/partners-article-960x768.jpg';

const cards: ImageCardProps[] = [
  {
    alt: 'Creative Talent: Past and Present Alumni',
    src: AlumniImage,
    subtitle: 'Highlighting our Alumni',
    title: 'Creative Talent: Past and Present Alumni',
    to: '/creative-talent/alumni',
  },
  {
    alt: 'Our Partners',
    src: PartnersImage,
    subtitle: 'Scholars Receive Funded Scholarships and Apprenticeships from Partners',
    title: 'Our Partners',
    to: '/creative-talent/our-partners',
  },
  {
    alt: 'BFI Flare show WBCT Alumni short film',
    src: BfiImage,
    subtitle: 'Sign up for exclusive access',
    title: 'BFI Flare show WBCT Alumni short film',
    to: '/creative-talent/bfi-flare-show-wbct-alumni-short-film',
  },
  {
    alt: 'Games Industry Biz name WBCT Alumni amongst the most influential women in the industry',
    src: GamesIndustryImage,
    subtitle: 'Excellent Work, Liz!',
    title: 'Games Industry Biz name WBCT Alumni amongst the most influential women in the industry',
    to: '/creative-talent/games-industry-biz-name-wbct-alumni-amongst-most-influential-women-industry',
  },
  {
    alt: 'Warner Bros Creative Talent Alumni BAFTA Nominated Short Film',
    src: BaftaImage,
    subtitle: 'Shot on location in Hackney',
    title: 'Warner Bros Creative Talent Alumni BAFTA Nominated Short Film',
    to: '/creative-talent/warner-bros-creative-talent-alumni-bafta-nominated-short-film',
  },
];

const alt = 'About Warner Bros. Creative Talent';
const imageWithPresets: ImageWithPresets = {
  image: {
    alt,
    src: CreativeTalentImage,
  },
  tabletImage: {
    alt,
    src: CreativeTalentTabletImage,
  },
  mobileImage: {
    alt,
    src: CreativeTalentMobileImage,
  },
};

const topBannerProps = {
  imageWithPresets,
  video: {
    bannerVideoId: 'b0czrMq1CzU',
  },
  titleProps: {
    title: 'About Warner Bros. Creative Talent',
    titleURL: '/creative-talent/about-wbct',
    addlTitleData: {
      socialIcons: [
        {
          type: 'facebook',
          url: 'https://www.facebook.com/WBCreativeTalent',
        },
        {
          type: 'twitter',
          url: 'https://twitter.com/WarnerBrosCT',
        },
      ],
    },
  },
};

// noinspection JSUnusedGlobalSymbols
export default () => (
  <DefaultLayout locale='en-GB'>
    <HeadTitle title='Creative Talent' />
    <FeaturedBanner headingTag='h1' {...topBannerProps} />
    <RowHeader title="Editor's Picks" />
    <StyledPaddingContainer>
      <ImageCards cards={cards} lazyLoad={true} ratio={0.8} />
    </StyledPaddingContainer>
  </DefaultLayout>
);

import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { black, darkGrey2, getTagColor, lightGrey2, white } from '../../common/colors';
import { hoverAspectContainerStyles } from '../aspect-ratio-image/AspectRatioImage.styles';

const cardGapPx = 20;

export const StyledImageCards = styled.div`
  overflow: hidden;
`;

export const StyledImageCardsInner = styled.div<{ invertColors?: boolean }>`
  background-color: ${({ invertColors }) => (invertColors ? black : white)};

  ${mediaQueryWidth({ min: 792 })`
    margin-right: -${rem(cardGapPx)};
  `}

  ${({ invertColors }) =>
    invertColors &&
    `
      ${ImageCardTitle} {
        color: ${white};
      }
    `}
`;

export const ImageCard = styled.div`
  ${hoverAspectContainerStyles};
  margin: 0 0 ${rem(30)} 0;

  & > a {
    display: block;
    line-height: 0;

    img {
      width: 100%;
    }
  }

  & > section {
    margin: ${rem(15)} 0 0 0;
  }

  ${mediaQueryWidth({ min: 792 })`
    display: inline-block;
    vertical-align: top;
    margin-right: ${rem(cardGapPx)};
    width: calc(50% - ${rem(cardGapPx)});

    &:nth-last-of-type(n+3), &:nth-last-of-type(n+3) ~ & {
      width: calc(33.33% - ${rem(cardGapPx)});
    }
  `}

  ${mediaQueryWidth({ min: 1056 })`
    &:nth-last-of-type(n+4), &:nth-last-of-type(n+4) ~ & {
      width: calc(25% - ${rem(cardGapPx)});
    }
  `}

  ${mediaQueryWidth({ min: 1320 })`
    &:nth-last-of-type(n+5), &:nth-last-of-type(n+5) ~ & {
      width: calc(20% - ${rem(cardGapPx)});
    }
  `}

  ${mediaQueryWidth({ min: 1968 })`
    &:nth-last-of-type(n+6), &:nth-last-of-type(n+6) ~ & {
      width: calc(16.66% - ${rem(cardGapPx)});
    }
  `}

  ${mediaQueryWidth({ min: 2292 })`
    &:nth-last-of-type(n+7), &:nth-last-of-type(n+7) ~ & {
      width: calc(14.28% - ${rem(cardGapPx)});
    }
  `}

  ${mediaQueryWidth({ min: 2652 })`
    &:nth-last-of-type(n+8), &:nth-last-of-type(n+8) ~ & {
      width: calc(12.5% - ${rem(cardGapPx)});
    }
  `}

  ${mediaQueryWidth({ min: 3012 })`
    &:nth-last-of-type(n+9), &:nth-last-of-type(n+9) ~ & {
      width: calc(11.11% - ${rem(cardGapPx)});
    }
  `}
`;

export const ImageCardHeader = styled.header`
  padding: 0 ${rem(10)};
  line-height: 0;
`;

export const ImageCardType = styled.div<{ category?: string }>`
  background-color: ${({ category }) => getTagColor(category)};
  color: ${black};
  display: inline-block;
  font-size: ${rem(10)};
  line-height: ${rem(13)};
  padding: ${rem(2)} ${rem(4)};
`;

export const ImageCardTypeContainer = styled.div<{ usePlaceholderPadding?: boolean }>`
  ${({ usePlaceholderPadding }) => (usePlaceholderPadding ? `padding-top: ${rem(20)};` : `margin-bottom: ${rem(2)};`)}

  & > ${ImageCardType} {
    margin-bottom: ${rem(3)};

    &:not(:last-of-type) {
      margin-right: ${rem(5)};
    }
  }
`;

export const ImageCardTitle = styled.div`
  display: inline-block;
  margin: 0;
  font-size: ${rem(17)};
  line-height: 1.2;
  text-transform: none;
  color: ${darkGrey2};
`;

export const ImageCardSubtitle = styled.div`
  margin-top: ${rem(5)};
  font-size: ${rem(12)};
  line-height: ${rem(15)};
  font-weight: 400;
  cursor: default;

  ${mediaQueryWidth({ min: 'mobile' })`
    font-size: ${rem(14)};
    line-height: ${rem(18)};
  `}
`;

export const ImageCardIconContainer = styled.div<{ ratio: number }>`
  position: relative;
  padding-top: ${({ ratio }) => ratio * 100}%;
  overflow: hidden;

  & > svg {
    position: absolute;
    width: 50%;
    height: auto;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    margin: 0 auto;
    color: ${lightGrey2};
  }
`;
